<template>
  <div>
    <div class="row">
      <div class="col-8">
        <el-tooltip placement="top" content="Ver Detalhes" effect="light">
        <i :class="openDetalhes ? 'fas fa-chevron-down' : 'fas fa-chevron-right'" class="but-drop-icone-linha-arquivo" @click="openDetalhes = !openDetalhes"></i>
        </el-tooltip>&nbsp;&nbsp;
        <span v-html="retornaIcone(propArquivo.props.content_type)"></span>&nbsp;&nbsp;<small>{{propArquivo.name}}</small> <small>({{propArquivo.metadata[0].tipo_arquivo}})</small>&nbsp;&nbsp;
        <el-tooltip placement="top" content="Copiar Link" effect="light">
          <i class="far fa-copy but-icone-linha-arquivo" @click="copiarLink(propArquivo.url)"></i>
        </el-tooltip>&nbsp;&nbsp;
        <el-tooltip placement="top" content="Ver o Arquivo" effect="light">
          <a class="but-icone-linha-arquivo text-reset" :href="propArquivo.url" target="_blank">
            <i class="fas fa-external-link-alt"></i>
          </a>
        </el-tooltip>
      </div>
      <div class="col-1">
        <small>{{calculaTamanhoArquivo(propArquivo.props.size)}}</small>
      </div>
      <div class="col-1">
        <small>{{propArquivo.metadata[0].dt_exclucao | formatDate}}</small>
      </div>
      <div class="col-1">
        <small>{{propArquivo.metadata[0].dt_envio | formatDate}}</small>
      </div>
    </div>
    <div class="row" v-show="openDetalhes">
      <div class="col-8">
        <small>Vaga: {{retornaTituloVaga(propArquivo.metadata[0].id_processo_vaga)}}</small> <el-tooltip placement="top" content="Ir para a Vaga" effect="light">
          <i class="fas fa-external-link-alt but-drop-icone-linha-arquivo" @click="goToVaga(propArquivo.metadata[0].id_processo_vaga)"></i>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "CompArquivoDetalhe",
  props: {
    propArquivo: { type: Object }
  },
  data() {
    return {
      openDetalhes: false
    }
  },
  computed: {
    vagas() {
      return this.$store.state.empresa.vagas;
    }
  },
  methods: {
    retornaIcone(ext) {
      if(ext === 'application/pdf') {
        return "<i class=\"far fa-file-pdf icone-linha-arquivo\"></i>"
      } else if(ext === 'image/png') {
        return "<i class=\"far fa-file-image icone-linha-arquivo\"></i>"
      }
    },
    calculaTamanhoArquivo(size) {
      var tam = size / 1024
      if(tam < 1000) {
         var res = new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(tam);
         return res + ' KiB'
      } else if(tam > 1000)  {
        var tam1 = tam / 1024
        var res = new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(tam1);
        return res + ' MiB'
      }
    },
    async copiarLink(valor) {
      try {
        await navigator.clipboard.writeText(valor);
        alert('Link copiado para a áera de transferência!');
      } catch($e) {
        alert('Ops! Não foi possível copiaro link...');
      }
    },
    retornaTituloVaga(id) {
      var vaga = this.vagas.find(v => v.id_processo_vaga === id)
      return vaga.nm_cargo;
    },
    goToVaga(id) {
      var vaga = this.vagas.find(v => v.id_processo_vaga === id)
      this.$router.push({ name: 'visualizarvaga', params: { url: vaga.hl_link} });
    },
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
        //return moment(String(value)).format("L");
      }
    },
  },
}
</script>

<style scoped>
.but-icone-linha-arquivo {
  font-size: 1rem;
  cursor: pointer;
}
.but-drop-icone-linha-arquivo {
  font-size: 0.9rem;
  cursor: pointer;
}
.icone-linha-arquivo {
  font-size: 1rem;
}
</style>
