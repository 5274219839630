<template>
  <div class="content">
    <base-header class="pb-6">
    </base-header>
    <div class="container-fluid mt--6">
      <div v-loading="loading">
        <card class="no-border-card" body-classes="px-0 pb-0" footer-classes="pb-2">
          <template slot="header">
            <div class="row justify-content-between">
              <div class="col-11 align-self-center">
                <h3 class="mb-0">Arquivos</h3>
              </div>
              <div class="col-1 align-self-center text-right">
                <i class="icone-help-bread-crumb text-default fas fa-question-circle" @click="drawer = !drawer"></i>
              </div>
            </div>
          </template>
          <div class="card-body pt-0" v-if="arquivos.length > 0">
            <h1>Lista de Arquivos</h1>
            <div class="row">
              <div class="col-8"><small>Nome do Arquivo</small></div>
              <div class="col-1"><small>Tamanho</small></div>
              <div class="col-1"><small>Enviado</small></div>
              <div class="col-1"><small>Expiração <el-tooltip placement="top" content="Data que o Arquivo poderá ser excluído pelo sistema" effect="light"><i class="fas fa-info-circle"></i></el-tooltip></small></div>
            </div>
            <div v-for="(file, index) in arquivos" :key="index">
              <CompArquivoDetalhe
                :propArquivo="file"
              />
              <div class="row">
                <div class="col-12">
                  <hr class="my-2">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col" v-if="!loading">
                <small>Espaço Total Utilizado: <strong>{{calculaEspacoOcupado()}}</strong></small>
              </div>
            </div>
            <!-- <pre>{{arquivos}}</pre> -->
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Loading, Drawer } from 'element-ui';
import moment from "moment";
import CompArquivoDetalhe from '../../components/Arquivos/CompArquivoDetalhe.vue';

Vue.use(Loading.directive);

export default {
  name: "Arquivos",
  components: {
    CompArquivoDetalhe,
    [Drawer.name]: Drawer,
  },
  data() {
    return {
      loading: true,
      arquivos: []
    }
  },
  computed: {
    plano() {
      return this.$store.state.empresa.plano;
    },
    empresa() {
      return this.$store.state.empresa.empresa;
    },
    vagas() {
      return this.$store.state.empresa.vagas;
    },
  },
  methods: {
    retornaIcone(ext) {
      if(ext === 'application/pdf') {
        return "<i class=\"far fa-file-pdf icone-linha-arquivo\"></i>"
      } else if(ext === 'image/png') {
        return "<i class=\"far fa-file-image icone-linha-arquivo\"></i>"
      }
    },
    calculaTamanhoArquivo(size) {
      var tam = size / 1024
      if(tam < 1000) {
         var res = new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(tam);
         return res + ' KiB'
      } else if(tam > 1000)  {
        var tam1 = tam / 1024
        var res = new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(tam1);
        return res + ' MiB'
      }
    },
    async copiarLink(valor) {
      try {
        await navigator.clipboard.writeText(valor);
        alert('Link copiado para a áera de transferência!');
      } catch($e) {
        alert('Ops! Não foi possível copiaro link...');
      }
    },
    calculaEspacoOcupado() {
      var count = 0;
      for(var i = 0; i < this.arquivos.length; i++) {
        count = count + this.arquivos[i].props.size;
      }
      var res = this.calculaTamanhoArquivo(count);
      return res;
    }
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
        //return moment(String(value)).format("L");
      }
    },
  },
  async mounted() {
    const data = {
      urlAmigavel: this.empresa.hl_url_amigavel
    }
    await this.$http.post('arquivos/listar', data)
      .then(res => {
        if(res.status === 200) {
          this.arquivos = res.data;
          this.loading = false;
        }
      })
  },
}
</script>

<style scoped>
.icone-help-bread-crumb {
  font-size: 1.6rem;
  cursor: pointer;
}
.but-icone-linha-arquivo {
  font-size: 1rem;
  cursor: pointer;
}
.but-drop-icone-linha-arquivo {
  font-size: 0.9rem;
  cursor: pointer;
}
.icone-linha-arquivo {
  font-size: 1rem;
}
</style>
